// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

// FA
@import "~font-awesome/scss/font-awesome";

/* tables */

.card-table {
    margin-bottom: 0;
}
.card-table tr:first-child td, .card-table tr:first-child th {
    border-top: 0;
}
.card-header .pagination,
.card-footer .pagination,
.card-header .form-group,
.card-header .card-title,
.card-header label
{
    margin-bottom: 0;
}

.aside-menu {
    flex-basis: 300px;
}

.print-inline .letter header .letter-id-block,
.print-inline .letter footer
{
    display: none;
}
